import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import Tooltip from 'rc-tooltip'
import { pull } from 'lodash'

import { notify } from 'Actions/notifications'
import handleValidate from 'Helpers/validators'
import Radio from 'Ui/Radio'
import Checkbox from 'Ui/Checkbox'
import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import './aml-modal.sass'

const expertFieldsValidators = {
  expert_name: ['required'],
  expert_address: ['required'],
}
const activityValidator = {
  activity: ['required'],
}

const ppeOriginValues = [
  'Épargne',
  'Héritage',
  'Donation',
  'Crédit',
  'Réinvestissement ou cession d’actifs mobiliers ou immobiliers',
  'Prestations ou Indemnités',
]

class AmlModalStep1 extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      ppeOrigin: [],
      ppeOtherChecked: false,
    }
  }
  
  handleSubmitValue(values, actions) {
    const { dispatch, currentUser, refreshUser } = this.props

    fetch(`/api/user/${currentUser.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(values),
    })
      .then(res => {
        actions.setSubmitting(false)
        if (res.status !== 200) throw new Error(res.error)
        return res
      })
      .then(res => res.json())
      .then(() => {
        dispatch(notify('success', 'Information enregistrée avec succès.'))
        refreshUser()
      })
      .catch((e) => {
        console.log('e ------------------------------------------>')
        console.log(e)
        if (e) dispatch(notify('error', 'Une erreur est survenue.'))
      })
  }

  renderSubmitButton(isSubmitting) {
    return (
      <div className="right-container mb-8">
        <SubmitSpinnerButton isSubmitting={isSubmitting}>
          <button type="submit" className="btn valid small-height">
            Valider
          </button>
        </SubmitSpinnerButton>
      </div>
    )
  }
  
  renderActivityQuestion() {
    const { currentUser } = this.props
    if (currentUser.activity) return null
    
    return (
      <Formik
        initialValues={{
          activity: '',
        }}
        validate={values => handleValidate(activityValidator)(values)}
        onSubmit={(values, actions) => this.handleSubmitValue(values, actions)}
        render={({ values, isSubmitting, errors, touched }) => {
          return (
            <Form>
              <div className="form-block">
                <div className="label-form">
                  <span className="step-subtitle lh22">Résumé de votre activité</span>
                </div>
                <div className="input">
                  <Field name="activity" component="textarea" className="Input-group" />
                  <span className="input-error-message">
                    {(touched.activity && errors.activity) || ''}
                  </span>
                </div>
                {this.renderSubmitButton(isSubmitting)}
              </div>
            </Form>
          )
        }}
      />
    )
  }
  
  setExpert(value, setFieldValue) {
    setFieldValue('has_expert', value)

    if (!value) {
      setFieldValue('expert_name', '')
      setFieldValue('expert_address', '')
    }
  }
  
  
  renderTooltip() {
    const tooltipText = (
      <div className="Tooltip">
        Nous avons l’obligation légale de connaitre le nom de votre cabinet comptable. Si vous n’en
        avez pas, vous serez vous-même désigné comme comptable.
      </div>
    )

    return (
      <Tooltip placement="top" trigger={['click']} overlay={tooltipText}>
        <span className="Tooltip-trigger">?</span>
      </Tooltip>
    )
  }
  
  renderExpertQuestion() {
    const { currentUser } = this.props
    if (currentUser.has_expert !== null) return null
    
    return (
      <Formik
        initialValues={{
          has_expert: false,
          expert_name: '',
          expert_address: '',
        }}
        validate={values => values.has_expert ? handleValidate(expertFieldsValidators)(values) : {}}
        onSubmit={(values, actions) => this.handleSubmitValue(values, actions)}
        render={({ values, isSubmitting, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <div className="form-block">

                <div className="label-form row">
                  <span className="step-subtitle lh22">Votre cabinet d'expert comptable</span>
                  {this.renderTooltip()}
                </div>
                <div className="radio-list">
                  <label>
                    <Radio
                      type="radio"
                      name="has_expert"
                      value={false}
                      onChange={() => this.setExpert(false, setFieldValue)}
                      checked={!values.has_expert}
                    />
                    <span>Je n'ai pas d'expert comptable</span>
                  </label>
                  <label>
                    <Radio
                      type="radio"
                      name="has_expert"
                      value={true}
                      onChange={() => this.setExpert(true, setFieldValue)}
                      checked={values.has_expert}
                    />
                    <span>J'ai un expert comptable :</span>
                  </label>
                </div>
                <div className="inputs-block">
                  <div className="input">
                    <Field
                      name="expert_name"
                      type="text"
                      className="Input-group"
                      placeholder="Nom du cabinet comptable"
                      onChange={e => {
                        setFieldValue('expert_name', e.target.value)
                        if (!values.has_expert) {
                          setFieldValue('has_expert', true)
                        }
                      }}
                    />
                    <span className="input-error-message">
                      {(values.has_expert && touched.expert_name && errors.expert_name) ||
                        ''}
                    </span>
                  </div>
                  <div className="input">
                    <Field
                      name="expert_address"
                      type="text"
                      className="Input-group"
                      placeholder="Adresse du cabinet comptable"
                      onChange={e => {
                        setFieldValue('expert_address', e.target.value)
                        if (!values.has_expert) {
                          setFieldValue('has_expert', true)
                        }
                      }}
                    />
                    <span className="input-error-message">
                      {(values.has_expert === true &&
                        touched.expert_address &&
                        errors.expert_address) ||
                        ''}
                    </span>
                  </div>
                </div>

                {this.renderSubmitButton(isSubmitting)}
              </div>
            </Form>
          )
        }}
      />
    )
  }
  
  setPpe(value, setFieldValue) {
    setFieldValue('ppe', value)

    if (!value) {
      this.setState({ ppeOrigin: [] })
      setFieldValue('ppeOriginOther', '')
    }
  }
  
  setPpeOrigin(value, setFieldValue, setFieldTouched, validateForm) {
    setFieldTouched('ppeOriginValidator', true)

    if (this.state.ppeOrigin.includes(value)) {
      this.setState({ ppeOrigin: pull(this.state.ppeOrigin, value) }, validateForm)
    } else {
      this.setState({ ppeOrigin: [...this.state.ppeOrigin, value] }, validateForm)
    }
  }
  
  renderPpeQuestion() {
    const { currentUser } = this.props
    if (currentUser.ppe !== null) return null
    
    const { firstname, name } = currentUser.representant
    
    return (
      <Formik
        initialValues={{
          ppe: false,
          ppeOriginOther: '',
          ppeOriginValidator: null,
        }}
        validate={values => {
          !values.ppe ||
          this.state.ppeOrigin.length ||
          (this.state.ppeOtherChecked && values.ppeOriginOther)
            ? {}
            : {
                ppeOriginValidator:
                  'Il est nécessaire de cocher une de ces cases si vous êtes une personne politiquement exposée.',
              }
        }}
        onSubmit={(values, actions) => this.handleSubmitValue({
          ppe: values.ppe,
          ppeOrigin: this.state.ppeOrigin.join(', '),
          ppeOriginOther: values.ppeOriginOther,
        }, actions)}
        render={({ values, isSubmitting, errors, touched, setFieldValue, setFieldTouched, validateForm }) => {
          return (
            <Form>
              <div className="form-block">
                <div className="label-form">
                  <span className="step-subtitle">Personne Politiquement Exposée</span>
                </div>
                <div className="ppe-text">
                  Est-ce que vous ou un membre de votre famille (conjoint, enfants ou leur conjoint,
                  parents) occupe l'une des fonctions suivantes ?
                  <ul>
                    <li>
                      Chef d’état, chef de gouvernement, membre d’un gouvernement national ou de la
                      Commission européenne
                    </li>
                    <li>
                      Membre d’une assemblée parlementaire nationale (député comme sénateur) ou du
                      Parlement européen, dirigeant d’un parti politique
                    </li>
                    <li>
                      Magistrat de la Cour de Cassation, conseiller d’Etat et membre du Conseil
                      Constitutionnel ou leurs équivalents à l’étranger
                    </li>
                    <li>Magistrat de la Cour des comptes ou leurs équivalents à l’étranger</li>
                    <li>Dirigeant ou membre de l’organe de direction d’une banque centrale</li>
                    <li>Ambassadeur ou chargé d’affaires</li>
                    <li>
                      Officier général, ou officier supérieur assurant le commandement d’une armée
                    </li>
                    <li>
                      Membre d’un organe d’administration, de direction ou de surveillance d’une
                      entreprise publique exerçant son activité au niveau national
                    </li>
                    <li>Directeur, directeur adjoint</li>
                    <li>Membre du conseil d’une organisation internationale</li>
                  </ul>
                </div>
                <div className="radio-list">
                  <label>
                    <Radio
                      type="radio"
                      name="ppe"
                      value={true}
                      onChange={() => this.setPpe(true, setFieldValue)}
                      checked={values.ppe}
                    />
                    <span>Oui</span>
                  </label>
                  <label>
                    <Radio
                      type="radio"
                      name="ppe"
                      value={false}
                      onChange={() => this.setPpe(false, setFieldValue)}
                      checked={!values.ppe}
                    />
                    <span>Non (le plus fréquent)</span>
                  </label>
                </div>
                {values.ppe ? (
                  <div className="radio-list">
                    <div className="ppe-text mb-5">
                      Je soussigné {firstname} {name}, atteste que les fonds m’ayant permis de
                      constituer le capital social de ma société ont pour origine (plusieurs choix
                      possibles) :
                    </div>
                    {ppeOriginValues.map(val => (
                      <label key={val}>
                        <Checkbox
                          type="checkbox"
                          name="ppeOrigin"
                          value={val}
                          onChange={() =>
                            this.setPpeOrigin(val, setFieldValue, setFieldTouched, validateForm)
                          }
                          checked={this.state.ppeOrigin.includes(val)}
                        />
                        <span>{val}</span>
                      </label>
                    ))}
                    <label>
                      <Checkbox
                        type="checkbox"
                        name="ppeOrigin"
                        onChange={() => {
                          setFieldTouched('ppeOriginValidator', true)
                          this.setState({ ppeOtherChecked: !this.state.ppeOtherChecked }, () => {
                            validateForm()
                          })
                        }}
                        checked={this.state.ppeOtherChecked}
                      />
                      <span>Autre :</span>
                    </label>
                    <Field name="ppeOriginOther" component="textarea" className="Input-group" />
                    <span className="input-error-message">
                      {(touched.ppeOriginValidator && errors.ppeOriginValidator) || ''}
                    </span>
                  </div>
                ) : null}
                
                {this.renderSubmitButton(isSubmitting)}
              </div>
            </Form>
          )
        }}
      />
    )
  }
  
  render() {
    const { currentUser } = this.props

    return (
      <>
        <h3>Mise à jour réglementaire de votre fiche client</h3>
        {this.renderActivityQuestion()}
        {this.renderExpertQuestion()}
        {this.renderPpeQuestion()}
      </>
    )
  }
}


const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(AmlModalStep1)
