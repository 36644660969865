import { each } from 'lodash'
import isValidSiren from 'Helpers/siren-validator'

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export default validationOptions => {
  return values => {
    const errors = {}
    each(validationOptions, (validators, field) => {
      if (!validators) return
      let error = null
      const value = values[field]

      if (!validators.includes('required') && value === '') return

      validators.forEach(type => {
        switch (type) {
          case 'required':
            if (!(value && (typeof value !== 'string' || value.trim()))) {
              error = 'Ce champ est requis'
            }
            break

          case 'no-leading-space':
            if (value && value.substring(0, 1) === ' ') {
              error = 'Ce champ ne doit pas commencer par un espace'
            }
            break

          case 'email':
            if (!emailRegex.test(value)) {
              error = 'Format de l’email incorrect'
            }
            break

          case 'tel':
            if (!/^(?:\+(\d{1,3})[-.\s]?)?(?:0|\d)(\d{8,11})$/i.test(value)) {
              error = 'Format du numéro incorrect'
            }
            break

          case 'secuNb':
            if (!/^\d{15}$/i.test(value)) {
              error = 'Format du numéro incorrect'
            }
            break

          case 'notZero':
            if (value === '0') {
              error = 'Ce champ est requis'
            }
            break

          case 'notNull':
            if (value === null) {
              error = 'Ce champ est requis'
            }
            break

          case 'max-length-60':
            if (value?.length > 60) {
              error = 'La longueur maximale est de 60 caractères'
            }
            break

          case 'max-length-14':
            if (value?.length > 14) {
              error = 'La longueur maximale est de 14 caractères'
            }
            break

          case 'min-length-4':
            if (value?.length < 4) {
              error = 'La longueur minimum est de 4 caractères'
            }
            break

          case 'no-special':
            if (!new RegExp(/^[A-Za-zÀ-ÿ0-9]+[A-Za-zÀ-ÿ+-.0-9' ]*$/, 'u').test(value)) {
              error = 'Les caractères spéciaux ne sont pas autorisés'
            }
            break

          case 'no-punctuation-except-hyphen': // to use with 'no-special' validator, because it only checks the punctuation allowed in no-special
            if (new RegExp(/[+-,.]/, 'u').test(value)) {
              error = 'La ponctuation n’est pas autorisée, excepté "-"'
            }
            break
          case 'siren':
            if (value.includes(' ')) {
              error = 'Merci d’enlever les espaces.'
            } else if (!value.trim()) {
              error = 'Vous devez renseigner votre Siren'
            } else if (!isValidSiren(value)) {
              error = 'Numéro de siren invalide'
            }
            break
          case 'date': {
            const regex = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/, 'u')

            if (!regex.test(value)) error = 'La date doit être au format JJ/MM/AAAA'
            break
          }
          default:
        }
      })

      if (error) errors[field] = error
    })

    return errors
  }
}
